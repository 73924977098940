import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ConfigureDropinService {
  constructor() {}

  configureCardHolderName(dropinConfig: any): void {
    dropinConfig.card = {
      cardholderName: {
        required: true,
      },
      overrides: {
        fields: {
          number: {
            placeholder: '---- ---- ---- ----',
            required: true,
            maskInput: {
              showLastFour: true,
            },
          },
          expirationDate: {
            required: true,
            maskInput: true,
          },
          cvv: {
            required: true,
            type: 'password',
          },
        },
      },
    };
  }

  configurePaypalCheckout(
    dropinConfig: any,
    chargeAmount: any,
    currency: any,
  ): void {
    dropinConfig.paypal = {
      flow: 'checkout',
      amount: chargeAmount,
      currency: currency,
    };
  }

  configurePaypalVault(dropinConfig: any) {
    dropinConfig.paypal = {
      flow: 'vault',
      displayName: 'payment page.',
    };
  }

  configureLocale(dropinConfig: any, locale: any) {
    dropinConfig.locale = locale;
  }

  configureGooglePay(dropinConfig: any, transactionInfo: any) {
    dropinConfig.googlePay = {
      googlePayVersion: 2,
      //merchantId: '5349834045',  //for UAT testing
      transactionInfo: transactionInfo,
      allowedPaymentMethods: [
        {
          type: 'CARD',
          parameters: {
            // BT recommend collecting and passing billing address information with all Google Pay transactions as a best practice.
            billingAddressRequired: true,
            billingAddressParameters: {
              format: 'FULL',
            },
          },
        },
      ],
    };
  }

  configureApplePay(dropinConfig: any, total: any) {
    dropinConfig.applePay = {
      displayName: 'My Store',
      paymentRequest: {
        label: 'My Store',
        total: total,
        // We recommend collecting billing address information, at minimum
        // billing postal code, and passing that billing postal code with all
        // Apple Pay transactions as a best practice.
        requiredBillingContactFields: ['postalAddress'],
      },
    };
  }
}
