export const environment = {
  production: true,
  envName: 'prod',
  autoClub: 'AANT',
  addressify: {
    baseUrl: 'https://api.addressify.com.au'
  },
  baseAPIUrl:
    'https://apim-aue-ltx-dev-api-01.azure-api.net/v1/quote/api/',
  DMSAPI: {
    baseUrl: 'https://apim-aue-ltx-dev.azure-api.net/v1/'
  },
  PASUrl: 'https://pasdev.tmaainsurance.com',
};
